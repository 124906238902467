var changeTitle = {

    titleContainer: 'header .title-container',
    previousTitleId: '0',

    _init: function(){
        
        this.registerEvents();

    },

    registerEvents: function(){        
        var self = this;
        $('.jar').mouseenter( function() { 
            self.showjJarTitle( this ); 
        }); 

        $('.jar').mouseleave( function() {
            self.hideJarTitle( this ); 
        }); 
    
    },

    showjJarTitle: function( hoveredJar ) {
        if( $(hoveredJar).hasClass('empty') ) {
            return;
        }
        this.changeJarTitle($( hoveredJar ).data('jam-title-id'));
    },

    hideJarTitle: function() {        
        this.changeJarTitle(0);
    },

    changeJarTitle: function( id ) {
        $(this.titleContainer).removeClass (function (index, className) {
            return (className.match (/(^|\s)visible-\S+/g) || []).join(' ');
        }).addClass('visible-' + id);
    }

}
$(document).ready(function(){
	changeTitle._init();
});
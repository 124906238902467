

var footerCarousel = {
	
	_init: function() {
		
		if( $('body#krakowjams').length === 0 ) {
			return;
		}
		
		this.registerEvents();
		
	},
	
	registerEvents: function() {
		
		$('.footer ul').slick({
			autoplay: true, 
			infinite: true,
			slidesToShow: 10,
			// slidesToScroll: 1,
			// autoplaySpeed: 0,
			// cssEase: 'linear',
			// speed:3000,
			// zmiana
			slidesToScroll: 2,
			// koniec zmiany
			arrows: false,
			pauseOnHover: true,
			swipe:false,
			responsive: [
				{
					breakpoint: 2440,
					settings: {
						slidesToShow: 8,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 1440,
					settings: {
						slidesToShow: 6,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 1025,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
		
	}
	
}


$(document).ready(function(){
	footerCarousel._init();
});
var menuVisible = {
    _init:function(){
        this.registerEvents();
    },
    registerEvents: function(){        
        $('#nav-icon1').click( this.toggleBackground );
    },
    toggleBackground: function() {    
        if( $('#nav-icon1').hasClass('openSign') ) {
            $('.new-menu').removeClass('open');
        } else {
            $('.new-menu').addClass('open');
        }
    }
}
$(document).ready(function(){
	menuVisible._init();
});
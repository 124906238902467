var darkBlendVisible = {
    _init:function(){
        this.registerEvents();
    },
    registerEvents: function(){        
        $('.switch').click( this.showDarkBlend );
        $('.switch2').click( this.hideDarkBlend );
    },
    showDarkBlend: function() {    

        $('.switch').removeClass('visibility');
        $('.dark-blend').addClass('visibility');
        $('.red-eyes').addClass('visibility');
},
    hideDarkBlend: function() {
    
        $('.dark-blend').removeClass('visibility');
        $('.switch').addClass('visibility');
        $('.red-eyes').removeClass('visibility');
        // $('.event-background').addClass('close');
}
}
$(document).ready(function(){
	darkBlendVisible._init();
});

// tippy('#kontakt', {
//     content: '<img src="{{ craft.app.config.general.rootUrl }}dist/img/krakowjams/web.png">',
//     arrow: true,
//     arrowType: 'sharp',
//     interactive: true
// })


// var template = document.getElementById('example')
// tippy('#kontakt', {
//     content: template.innerHTML,
//     arrow: true, 
//     arrowType: 'sharp',
//     placement: 'top-start',
//     interactive: true
// })


var template = document.getElementById('contactMore')
var container = document.createElement('div') 
container.appendChild(document.importNode(template.content, true))

tippy('#kontakt', {
    content: container.innerHTML,
    arrow: true,
    arrowType: 'sharp',
    delay: [0,200],
    interactive: true,
    offset: "110,0",

})
var eventBackgroundVisible = {
    _init:function(){
        this.registerEvents();
    },
    registerEvents: function(){        
        $('.jar').click( this.showBackground );
        $('.background-bar').click( this.hideBackground );
    },
    showBackground: function() {    
        if( $(this).hasClass('empty') ) {
            return;
        }
        var id = $(this).data('jam-title-id');
        var index = id - 1;
        $('.event-background').eq(index).addClass('open');

},
    hideBackground: function() {
    
        $('.event-background').removeClass('open');
        // $('.event-background').addClass('close');
}
}
$(document).ready(function(){
	eventBackgroundVisible._init();
});